/* eslint-disable */
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/responsive.css';
import './css/map.css';
import $ from 'jquery';
import Popper from 'popper.js';
import Animate from 'animate.css-react';
import React, {useEffect,useState} from 'react';
import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import { render } from '@testing-library/react';


import Header from './Header';
import Showcase from './Showcase';
import Blocks from './components/Blocks';
import Home from './Home';
import Footer from './Footer';
function App() {
  return (
    <div>
      <Header/> 
      <Showcase/>
      <Blocks/> 
      <Footer/>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
        </Routes> 
      </Router>
    </div>
  );
}


export default App;

