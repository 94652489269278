import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Blocks()
{
    
    const [homepageblocks, setHomeblocks] = useState([]);
    useEffect(() => {
    fetchHomeblocks();
    }, []);
    const fetchHomeblocks = async () => {
    const result=await axios.get('https://v2.stylianidesgroup.com/api/blocks.php');
    setHomeblocks(result.data.records)
	console.log(result);
    }
    
  
    const blockDetail = homepageblocks.map((homepageblocks) => {

  
      let url=homepageblocks.front_block_url;

        if(url==='firstblock.inc.php') {
          
             return (<><div><FirstBlock /></div></>);
        } else if(url==='secondblock.inc.php') {
          
          return (<><div><SecondBlock /><ThirdBlock /></div></>);
     } else {
     	return (<><div></div></>);
     }
        
        });
    
        return blockDetail;
  };

  function FirstBlock(){

    const [firstBlock, setFirstblock] = useState([]);
    useEffect(() => {
    fetchFirstblock();
    }, []);
    const fetchFirstblock = async () => {
    const result=await axios.get('https://v2.stylianidesgroup.com/api/firstblock.php');
    setFirstblock(result.data.records)
    console.log(result);
    }

    
              return(
              <div>
              <section className='properties'>
                              <ul className='nav nav-pills mb-3 justify-content-center' id='pills-tab' role='tablist'>
                    {firstBlock.map((firstBlock, index) => (
                  
                    
                        
                                <li className='nav-item' role='presentation'>
                                <button className={index === 0 ? "nav-link active" : "nav-link" } id={'pills-home-tab-'+firstBlock.ID} data-bs-toggle="pill" data-bs-target={"#pills-"+firstBlock.ID}
                                type="button" role="tab" aria-controls={firstBlock.ID} aria-selected="true">{firstBlock.tabTitle}</button>
                                </li>
                         ))}
                              
                          </ul>
                          <div className="tab-content" id="pills-tabContent">
                            {firstBlock.map((firstBlock, index) => (
                              <div className={index === 0 ? "tab-pane fade show active" : "tab-pane fade show" } id={'pills-'+firstBlock.ID} role="tabpanel" aria-labelledby="pills-home-tab">
                                <PropBlock dataValue={firstBlock.ID} />
                              </div>
                              ))}
                            </div>
                    </section>
                </div>
              )
            
    
  }

  function PropBlock(props){

    const [propBlock, setPropblock] = useState([]);
    useEffect(() => {
    fetchPropblock();
    },[]);
    const fetchPropblock = async () => {
    const result=await axios.get('https://v2.stylianidesgroup.com/api/properties-block.php', { params: { proID: props.dataValue } });
    setPropblock(result.data.records)
    //console.log(result);
                                                  }
                                                  const imgStyle = {
                                                    width: "635px",
                                                    height: "320px",
                                                  
                                                  };
    
              return(
              
                          <>
                                          
                                        
                                          
                                                
                                        

                                          <div className="properties-row wow slideInUp">
                                          {propBlock.map((propBlock, index) => (
                                          <div className="property-grid">
                                          <a href={propBlock.URL}>
                                            <div className="property">
                                          <div className="proprty-img">
                                            <img src={propBlock.propertyImage} style={imgStyle} alt={propBlock.propertyName} />
                                          </div>
                                          <div className="p-bio">
                                            <h3>{propBlock.propertyName}</h3>
                                            <a href={propBlock.URL}>Explore</a>
                                          </div>
                                        </div>
                                        </a>
                                      </div>
                                          ))}
                                            </div>
                                              

                                        
                                        
                               
                              
                        
                      
                  

                              
                           </>
              
              )
            
    
  }


  function SecondBlock(){


    const [secondBlock, setSecondblock] = useState([]);
    useEffect(() => {
    fetchSecondblock();
    }, []);
    const fetchSecondblock = async () => {
    const result=await axios.get('https://v2.stylianidesgroup.com/api/secondblock.php');
    setSecondblock(result.data.records)
    console.log(result);
    }

    
              return(

                <>
                        <div>
                        <section className="quality-sec">
                        <div className="container">
                        {secondBlock.map((secondBlock, index) => (

                <>
                              {secondBlock.inum === "0" ?
                                      <div className="row title-text" key={index}>
                                                      <div className="col-lg-12 text-center">
                                                          <h2 className="pb-3">{secondBlock.blockTitle}</h2>
                                                          <p dangerouslySetInnerHTML={{ __html: secondBlock.blocksubTitle }} />
                                                      </div>
                                        </div>
                                      : null}
                                  </>
                          ))}
                          <div className="row quality-row wow slideInUp">
                          {secondBlock.map((secondBlock, index) => (

                                <>
                                {secondBlock.inum === "0" ? null
                                                                      : <div className="col-md-4">
                                                                      <div className="quality">
                                                                      <a href={secondBlock.linkStart}>
                                                                              <p>{secondBlock.tabTitle}</p>
                                                                              {secondBlock.linkEnd}     
                                                                      </a>  
                                                                      </div>
                                                                  </div> }
                                  </>
                                  ))}
  </div>
                          </div>
                        </section>
                        
                        </div>
                  </>

              );

  }

  function ThirdBlock(){

    const [thirdBlock, setThirdblock] = useState([]);
    useEffect(() => {
    fetchThirdblock();
    }, []);
    const fetchThirdblock = async () => {
    const result=await axios.get('https://v2.stylianidesgroup.com/api/thirdblock.php');
    setThirdblock(result.data.records)
    //console.log(result);
    }

    
              return(

                <>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="cta-row align-items-center">
                            {thirdBlock.map((thirdBlock, index) => (
<>
                              <div className="text">
                              <p dangerouslySetInnerHTML={{ __html: thirdBlock.blockTitle }} />
                              </div>
                              <div className="line"></div>
                        <div className="cta-btn">
                            
                            <button type="button" className="btn btn-outline-dark rounded-pill btn-lg">{thirdBlock.readmore}</button>
                            
                        </div>
                        </>
                            ))}
                          </div>
                        </div>
                    </div>
              </div>
                </>
              )
  


  }
 
  export default Blocks;