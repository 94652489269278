import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import GoogleMapComponent from './components/GoogleMapComponent';
//import Tracker from './components/Tracker';

import axios from 'axios';
function Footer(){



    const [footerBlock, setFooterblock] = useState([]);
        useEffect(() => {
        fetchFooterblock();
        }, []);
        const fetchFooterblock = async () => {
        const result=await axios.get('https://v2.stylianidesgroup.com/api/socialmedia.php');
        setFooterblock(result.data.records)
       // //console.log(result);
        }


        const [propertyMap, setpropertyMap] = useState([]);
        useEffect(() => {
        fetchpropertyMap();
        }, []);
        const fetchpropertyMap = async () => {
        const result=await axios.get('https://v2.stylianidesgroup.com/api/map.php');
        setpropertyMap(result.data.records)
       console.log(result);
        }

        

    return(

    
        <>
           <section className="our-locations">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h3 className="wow slideInUp">Our Projects</h3>
                    <div className="more-btn line-btn text-center pt-5">
                        <button type="button"
                            className="btn btn-outline-dark out-lined-btn btn-lg text-uppercase btn-locations">
                            <span>The Locations</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="locations-map pos-rel">
                   <div className="main-map__box">
            <div className="main-map__box-title">
                <h4>Our Projects</h4></div>
            <div className="main-map__box-list scrollbar">
            {propertyMap.map((propertyMap, index) => (
                <div className="main-map__box-item" data-index={propertyMap.index}>{propertyMap.propertyName}</div>
                ))}
            </div>
        </div>
        <div id="map" class="map">
        <GoogleMapComponent />
        </div>
                </div>
</section>
<section className="follow-sec">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                {footerBlock.map((footerBlock, index) => (

<>
              {footerBlock.inum === "0" ?
                      <><h3 className="wow slideInUp">{footerBlock.blockTitle}</h3>
                      <p className="pb-4">{footerBlock.blocksubTitle}</p></>
                      : null}
                  </>
          ))}
                    
                    <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        {footerBlock.map((footerBlock, index) => (

                <>
                            {footerBlock.inum === "0" ?
                                    null
                                    : <><li className="nav-item" role="presentation">
                                    <button className={index === 1 ? "nav-link active" : "nav-link" } id="pills-home-tab" data-bs-toggle="pill"
                                        data-bs-target={'#pills-'+footerBlock.socialTitle} type="button" role="tab" aria-controls="pills-home"
                                        aria-selected="true">{footerBlock.socialTitle}</button>
                                </li></>
                                }
                                </>
                            ))}
</ul>
                    <div className="tab-content  text-center" id="pills-tabContent">
                    {footerBlock.map((footerBlock, index) => (

<>
            {footerBlock.inum === "0" ?
                    null
                    : <><div className={index === 1 ? "tab-pane fade show active" : "tab-pane fade show" } id={"pills-"+footerBlock.socialTitle} role="tabpanel"
                            aria-labelledby="pills-facebook-tab">
							<p>
								<a href="{footerBlock.linkStart}" target='_blank'>
									<i className={"fa fa-"+footerBlock.socialicon} aria-hidden="true"></i><span>CCS Stylianides Group</span></a></p>
                        </div></>
                                }
                                </>
                                ))}
                    </div>
                </div>
            </div>
        </div>
     </section>
<section className="newsletter">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="subs-news" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <div className="news-btn more-btn line-btn">
                            <button type="button" className="btn btn-outline-dark out-lined-btn btn-lg text-uppercase">
                                <span>Subscribe to our newsletter</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade newsletter-pop" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">Subscribe to our newsletter</h5>
                            <p className="para para-large">To receive updates about exclusive experiences,<br/> subscribe to our newsletter and keep up to date</p>
                            <p className="para para-xs">We do not store your personal data. <br/>We only use it to contact you about your enquiry</p>
                            <form>
                                <div className="input-group ">
                                    <input type="text" className="form-control" placeholder="First Name"/>
                                </div>
                                <div className="input-group ">
                                    <input type="text" className="form-control" placeholder="Last Name"/>
                                </div>
                                <div className="input-group ">
                                    <input type="email" className="form-control" placeholder="Email Address"/>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Please click here to confirm communications consent to receive updates from CCS Stylianides Group.
                                    </label>
                                </div>
                                <p className="para para-xs form-note"> By confirming “Yes”, you consent to CCS Stylianides Group and it’s respective current and future subsidiaties and affiliates contacting you regarding current and future projects, services, newsletters, events, announcements and other publications. You may withdraw your consent at any time by emailing us at: <a href="mailto:marketing@stylianidesgroup.com">marketing@stylianidesgroup.com</a>. 
                                </p>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <div className="container-fluid">
                        <div className="row">
                <div className="col-sm-12">
                    <ul className="nav justify-content-center">
                                                <li className="nav-item">
                            <a className="nav-link" href="/pages/about-us" >About</a>
                        </li>
                                            <li className="nav-item">
                            <a className="nav-link" href="/properties/sale" >For Sale</a>
                        </li>
                                            <li className="nav-item">
                            <a className="nav-link" href="/properties/rent" >For Rent</a>
                        </li>
                                            <li className="nav-item">
                            <a className="nav-link" href="/projects" >Projects</a>
                        </li>
                                            <li className="nav-item">
                            <a className="nav-link" href="/services" >Services</a>
                        </li>
                                            <li className="nav-item">
                            <a className="nav-link" href="/contact" >Contact</a>
                        </li>
                                        </ul>
                </div>
            </div>
                        <div className="row pt-3 pb-4 align-items-center">
                <div className="justify-content-end">
                    <div className="footer-logo text-center">
                        <img src="https://www.stylianidesgroup.com/images/footer-logo.png" />
                    </div>
                </div>
            </div>
            <div className="row footer-social-icons">
                <div className="col-sm-12">
                    <div className="justify-content-end">
                        <div className="follow mx-auto">
                                                            <div className="social-icons">
                                                                        <a href="https://www.facebook.com/C2SStylianides/about/?ref=page_internal" target='_blank'><i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                                                                        <a href="https://www.instagram.com/ccs_stylianides_group/" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                                                    </div>
                                                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row pt-3 pb-3">
                <div className="col-lg-6 text-start copyright">
                    Copyright
                    ©  Stylianides Group All rights reserved.
                    2022                </div>
                <div className="col-lg-6 text-end">
                   <div className="footer-wb-logo">Website Development By &nbsp; <a target="_blank" href="http://websitebakers.eu" id="bakers"><img src="https://www.stylianidesgroup.com/images/footer-wb-logo.png" className="websitebakers-logo" /></a></div>
                </div>
            </div>
        </div>
    </footer>

        </>
    )


    
}




export default Footer;