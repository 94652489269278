import React from 'react';
function Header(){

          return(
        <div>
            <header className="fixed-top">
            <div className="info-bar">
            <div className="container-xxl">
                <div className="row ">
                    <div className="col-md-5 d-flex align-items-center hide-on-tab">
                        <div className="phone-num">
                            <div className="icon">
                                <i className="fa fa-mobile" aria-hidden="true"></i>
                            </div>
                            <div className="content">
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Cyprus
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="javascript:void()">Action</a></li>
                                        <li><a className="dropdown-item" href="javascript:void()">Another action</a></li>
                                        <li><a className="dropdown-item" href="javascript:void()">Something else here</a></li>
                                    </ul>
                                </div>
                                <div className="num">70003004</div>
                            </div>

                        </div>
                        <div className="follow">
                            <div className="text">Follow us</div>
                            <div className="line"></div>
                            <div className="social-icons">
                                <a href="javascript:void()"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                <a href="javascript:void()"><i className="fa fa-linkedin-square" aria-hidden="true"></i></a>
                                <a href="javascript:void()"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                <a href="javascript:void()"><i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-2 col-sm-4 col-3 d-flex align-items-center">
                        <a href="index.html"> <img className="top-logo" src="images/logo.png" /></a>
                    </div>

                    <div
                        className="col-md-5 col-sm-8 col-9 justify-content-sm-center d-flex align-items-center justify-content-end">
                        <div className="search-field">
                            <div className="input-group rounded-pill">
                                <div className="form-outline">
                                    <input type="search" id="form1" placeholder="Search" className="form-control" />
                                </div>
                                <button type="button" className="btn btn-primary">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>

                        <div className="lang">
                            <div className="btn-group">
                                <button className="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    EN
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="javascript:void()">FR</a></li>
                                    <li><a className="dropdown-item" href="javascript:void()">AE</a></li>
                                </ul>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </div>


        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav m-auto">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="about.html">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="properties.html">For Rent</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="properties.html">For Sale</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="properties.html">Projects</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="services.html">Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="javascript:void()">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>   
            </header>
            
            </div>
          )
  }

  export default Header;