import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Showcase()
{
  const [showcase, setShowcase] = useState([]);
useEffect(() => {
  fetchShowcase();
}, []);
const fetchShowcase = async () => {
 const result=await axios.get('https://v2.stylianidesgroup.com/api/showcase.php');
 setShowcase(result.data.records)
 //console.log(result);
};
return (
    <>
       <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
            </div>
            <div className="carousel-inner">
                {showcase.map((showcase, index) => (
                    //var indexNum==={index+1};

                <div key={index} className={index === 0 ? "carousel-item text-center active" : "carousel-item text-center" } >
                    
                    <img className="fill d-block w-100" alt="{showcase.showcase_name}" src={showcase.showcaseImage} />
                </div>
                ))}
            

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div className="after-banner-shape"></div> 
    </>
  );
};
export default Showcase;