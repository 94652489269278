import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
//import Mapdata from './mapdata.php';
//const google = window.google;
const customizeMap = {
	width: '100%',
	height: '100%'
};
class GoogleMapComponent extends React.Component
{

	constructor(props)
	{
		super(props);
		this.state = {
			cords: []
		}
	}
	//ComponentDidMount is use to Connect a React app to external applications, such as web APIs or JavaScript functions
	componentDidMount(){
        //get request
        axios.get('https://v2.stylianidesgroup.com/api/mapdata.php').then(res => 
        {
        
        this.setState({cords: res.data});
		console.log(res.data);
           }); 
        
        }
	drawMarker = () => {
		 return this.state.cords.map(
			(info,i)=>{
			return <Marker key={i} id={i} position={{
					lat: info.lat,
					lng: info.lng,
					image: 'https://stylianidesgroup.com/Assets/Images/uploadedContent/CMS/propertiesImages/cypruslandforsale1443791345.gif',
					title: 'LAND IN YPSONAS',
					link: 'project/land-in-ypsonas',
				}} icon={{

					url: '../images/marker.png',

					

				}}
			onClick={() => console.log("Event Hanlder Called")}>

			</Marker>
		})
	}
	render()
	{
		return (
		<Map
		className="map"
		google={this.props.google
	}
	style={customizeMap}
	icon={require('../images/marker.png')}
	zoom={13}
	initialCenter={{
	lat: 34.715984344482,
	lng: 33.105278015137
	}}>
	{this.drawMarker()}
	</Map>
	);
}
}
export default GoogleApiWrapper({
	apiKey: 'AIzaSyCXhBKreSUdmjPBuYG-POFRE6ttq_q2bok'
})(GoogleMapComponent);